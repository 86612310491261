<template>
	<div flex="cross:center main:center dir:top" style="min-height: 261px;">
		<img style="margin: 40px;" src="../assets/imgs/my/null-data.png" alt="" />
		<slot name="text">
			<p :style="'color: ' + (color || '#AFB2BD')" style="position: relative; font-size: 14px; color: ; line-height: 14px; top: -40px;">{{text || '暂无数据'}}</p>
		</slot>
	</div>
</template>

<script>
export default {
	name: 'emptyTableList',
	props: ['text', 'color'],
	data() {
		return {
		}
	},
	created() {
	},
	methods: {
	}
}
</script>

<style scoped lang="scss">
</style>
