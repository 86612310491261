
export default [
	{
		path: 'announcement',
		name: 'announcement',
		meta: { title: "公告公示" },
		component: function (resolve) {
			require(['../views/announcement/index.vue'], resolve)
		},
	}
]