
export default [
	{
		path: 'home',
		name: 'home',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/home.vue'], resolve)
		},
	},
	{
		path: '/search',
		name: 'searchIndex',
		// component: () => import('../components/login.vue'),
		component: function (resolve) {
			require(['../views/search/index.vue'], resolve)
		}
	},
	{
		// 政策法规
		path: 'policiesAndRegulations',
		name: 'policiesAndRegulations',
		component: function (resolve) {
			require(['../views/policiesAndRegulations/index.vue'], resolve)
		},
	},
	{
		// 政策法规详情
		path: 'policiesDetail',
		name: 'policiesDetail',
		component: function (resolve) {
			require(['../views/policiesAndRegulations/detail.vue'], resolve)
		},
	},
	{
		// 供需市场
		path: 'market',
		name: 'market',
		component: function (resolve) {
			require(['../views/market/index.vue'], resolve)
		},
	},
	{
		// 供需市场详情
		path: 'marketDetail',
		name: 'marketDetail',
		component: function (resolve) {
			require(['../views/market/detail.vue'], resolve)
		},
	},
	{
		// 供需市场查看更多
		path: 'marketMore',
		name: 'marketMore',
		component: function (resolve) {
			require(['../views/market/more.vue'], resolve)
		},
	},
	{
		// 评估机构
		path: 'evaluationAgency',
		name: 'evaluationAgency',
		component: function (resolve) {
			require(['../views/evaluationAgency.vue'], resolve)
		},
	},
	{
		// 招聘市场
		path: 'recruitmentMarket',
		name: 'recruitmentMarket',
		component: function (resolve) {
			require(['../views/recruitmentMarket.vue'], resolve)
		},
	}
]