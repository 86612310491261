
export default [
	{
		//竞拍大厅
		path: 'biddingIndex',
		name: 'biddingIndex',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/bidding/biddingIndex.vue'], resolve)
		},
	},
	{
		//竞拍详情
		path: 'biddingDetail',
		name: 'biddingDetail',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/bidding/biddingDetail.vue'], resolve)
		},
	}
]