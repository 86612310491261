<template>
	<div>
		<transition name="popup">
			<div class="login-box" v-show="$store.state.showLoginBox" flex="main:center cross:center">
				<div>
					<img class="close" src="@/assets/imgs/icon/close-icon-black.png" @click="$store.state.showLoginBox = false" />
					<div class="tab" flex>
						<p :class="{on: loginType == 0}" @click="loginType = 0">手机登录</p>
						<p :class="{on: loginType == 1}" @click="loginType = 1">账号登录</p>
					</div>
					<div v-show="loginType == 0" flex="cross:center" class="input-row">
						<div class="img-w" flex="main:center cross:center">
							<img flex-box="0" src="@/assets/imgs/icon/login-phone.png" />
						</div>
						<span>+86</span>
						<input maxlength="11" flex-box="1" placeholder="请输入手机号" v-model="phone" @input="$utils.positiveInteger($data, 'phone')" />
					</div>
					<div v-show="loginType == 0" flex="cross:center">
						<div flex="cross:center" class="input-row" flex-box="1">
							<div class="img-w" flex="main:center cross:center">
								<img flex-box="0" src="@/assets/imgs/icon/login-phone.png" />
							</div>
							<input maxlength="4" flex-box="1" placeholder="请输入图形验证码" v-model="imgCodeVal" @input="$utils.positiveInteger($data, 'imgCodeVal')" />
						</div>
						<img class="pointer" flex-box="0" @click="getCodeImg" :src="codeImg" style="height: 48px; margin: 24px 0px 0px 10px;" />
					</div>
					<div v-show="loginType == 0" flex="cross:center" class="input-row">
						<div class="img-w" flex="main:center cross:center">
							<img flex-box="0" src="@/assets/imgs/icon/login-code.png" />
						</div>
						<input maxlength="6" v-model="code" flex-box="1" placeholder="请输入验证码" />
						<p class="get-code" @click="getCode()">{{codeTxt}}</p>
					</div>
					
					<div v-show="loginType == 1" flex="cross:center" class="input-row">
						<el-select :popper-append-to-body="false" placeholder="请选择用户类型" v-model="roleId" class="JTJJ" flex-box="1">
							<el-option value="2" label="普通用户">普通用户</el-option>
							<el-option value="5" label="集体用户">集体用户</el-option>
						</el-select>
					</div>
					<div v-show="loginType == 1" flex="cross:center" class="input-row">
						<div class="img-w" flex="main:center cross:center">
							<img flex-box="0" src="@/assets/imgs/icon/login-phone.png" />
						</div>
						<input flex-box="1" placeholder="请输入账号" v-model="accountName"/>
					</div>
					<div v-show="loginType == 1" flex="cross:center" class="input-row">
						<div class="img-w" flex="main:center cross:center">
							<img flex-box="0" src="@/assets/imgs/icon/login-code.png" />
						</div>
						<input v-model="password" flex-box="1" placeholder="请输入密码" type="password" />
					</div>
					<div class="agreement" flex="main:justify">
						<p>登录即同意《<span @click="showBox = true; title = '服务协议'; agreementType = 5" class="pointer">服务协议</span> 》及《<span @click="showBox = true; title = '平台隐私政策'; agreementType = 6" class="pointer">平台隐私政策</span>》</p>
						<p>忘记密码？</p>
					</div>
					<div class="btn">
						<div @click="login()">登录</div>
						<div @click="$router.push('/register')">注册个人用户</div>
					</div>
				</div>
			</div>
		</transition>
		
		<agreementBox :showBox.sync="showBox" v-show="$store.state.showLoginBox && showBox" :type.sync="agreementType" :title="title"></agreementBox>
	</div>
</template>

<script>
import agreementBox from './agreementBox.vue'

export default {
	name: "loginBox",
	components: {
		agreementBox
	},
	watch: {
		'$store.state.showLoginBox'() {
			this.getCodeImg();
		}
	},
	data() {
		return {
			agreementType: '',
			title: '',
			showBox: false,
			codeTxt: "获取验证码",
			loginType: 0,
			phone: "", // 137000000002
			code: "",

			roleId: '',
			accountName: '',
			password: "",
			codeImg: '',
			codeImgKey: '',
			imgCodeVal: ''
		}
	},
	created() {
		this.getCodeImg();
	},
	methods: {
		getCodeImg() {
			this.$ajax({
				url: '/anony/get/image/code',
				method: 'get',
				data: {
					type: 3,
				},
				success: res => {
					this.codeImg = 'data:image/jpg;base64,' + res.data.base64;
					this.codeImgKey = res.data.imageKey;
				}
			})
		},
		// 获取验证码
		getCode() {
			if(this.imgCodeVal.length != 4) {
				this.$message.error("请输入正确的图形验证码");
				return false;
			}

			if (this.codeTxt != "获取验证码") {
				this.$message.error("请勿频繁获取验证码");
				return false;
			}

			if (!this.$utils.checkPhone(this.phone)) {
				this.$message.error("请输入正确的手机号");
				return false;
			}

			this.$ajax({
				url: "/anony/send",
				data: {
					phone: this.phone,
					type: 1,
					imageKey: this.codeImgKey,
					imageCode: this.imgCodeVal
				},
				success: (res) => {
					let count = 60;
					this.codeTxt = "60S";
					let timer = setInterval(() => {
						if (count == 0) {
							clearInterval(timer);
							this.codeTxt = "获取验证码";
						} else {
							count--;
							this.codeTxt = count + "S";
						}
					}, 1000);
				},
			});
		},
		login() {
			let data = {
				type: 2
			};

			if (this.loginType == 0) {
				if (!this.$utils.checkPhone(this.phone)) {
					this.$message.error("请输入正确的手机号");
					return false;
				}

				if (!this.code) {
					this.$message.error("请输入验证码");
					return false;
				}

				data.code = this.code;
				data.username = this.phone;
			} else {
				if(!this.roleId) {
					this.$message.error("请选择用户类型");
					return false;
				}

				if (!this.accountName) {
					this.$message.error("请输入用户账号");
					return false;
				}

				
				if (!this.password) {
					this.$message.error("请输入密码");
					return false;
				}

				data.roleId = this.roleId;
				data.password = this.$md5(this.password);
				data.username = this.accountName;
			}

			this.$ajax({
				url:
					this.loginType == 0
						? "/anony/phone/login"
						: "/anony/login",
				method: 'get',
				data: data,
				success: (res) => {
					this.$store.state.token = res.data.token;
					localStorage.setItem("userToken", res.data.token);

					this.$ajax({
						url: "/cg/user/info",
						method: "get",
						success: (res) => {
							this.$store.state.userInfo = res.data;
							localStorage.setItem("userId", res.data.userId);

							this.$message.success('登录成功');
							this.$store.state.showLoginBox = false;
							window.location.reload();
						},
					});
				},
			});
		}
	},
};
</script>

<style scoped lang="scss">
@import '@/styles/input.scss';

.login-box{
	position: fixed;
	background: rgba($color: #000000, $alpha: 0.3);
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1999;

	> div{
		width: 600px;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 75px 70px 0;
		position: relative;

		.close{
			cursor: pointer;
			position: absolute;
			top: 29px;
			right: 33px;
		}

		.tab{
			margin: 0 10px 36px;
			border-bottom: 2px solid #E1E1E1;

			p{
				padding: 0 3px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #989BA6;
				line-height: 16px;
				position: relative;
				padding-bottom: 11px;
				margin-right: 20px;
				cursor: pointer;

				&.on{
					color: #2E89EE;
					position: relative;
					
					&::before{
						display: block;
						content: '';
						position: absolute;
						bottom: -2px;
						left: 0;
						right: 0;
						height: 2px;
						background: #2E89EE;
					}
				}
			}
		}

		.input-row{
			margin-top: 24px;
			height: 48px;
			background: #F8F8F8;
			border: 1px solid #D1D1D1;
			border-radius: 2px;

			.img-w{
				width: 45px;
				margin-left: 7px;
			}

			span{
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #989BA6;
				line-height: 14px;
				padding-right: 3px;
			}

			input{
				border: none;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;

				&::-webkit-input-placeholder {
					color: #989BA6;
				}
			}

			.get-code{
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				text-decoration: underline;
				color: #2E89EE;
				line-height: 14px;
				padding: 0 10px;
				cursor: pointer;
			}

			.JTJJ{
				::v-deep {
					input {
						background: rgba(0,0,0,0);
					}
				}
			}
		}

		.agreement{
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #989BA6;
			line-height: 12px;
			padding: 22px 0 29px;

			span{
				color: #2E89EE;
			}
		}

		.btn{
			padding-bottom: 20px;

			div{
				margin-bottom: 20px;
				cursor: pointer;

				&:nth-child(1){
					height: 40px;
					background: #2E89EE;
					box-shadow: 0px 3px 7px 0px rgba(46,137,238,0.35);
					border-radius: 4px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 40px;
					text-align: center;
				}
			
				&:nth-child(2){
					height: 40px;
					background: #FFFFFF;
					border: 1px solid #2E89EE;
					border-radius: 4px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #2E89EE;
					line-height: 38px;
					text-align: center;
				}
			}
		}
	}
}
</style>