import {
	transferOut,
	tradingType,
	paymentWay,
	guaranteeTypes,
  guaranteeTypes1,
	projStatus,
	registerStatus,
	listPriceUnits,
	ownerTypes,
	assignorTypes,
  transferOutAgain,
  payment,
  expiry,
  targeTypes,
  assetEquity,
  quantityUnit,
  biddingMethod,
  maxQuota,
  applyPeopleTypeList
} from './constant'

const getters = {
  transferOut: state => transferOut,
  tradingType: state => tradingType,
  paymentWay: state => paymentWay,
  guaranteeTypes: state => guaranteeTypes,
  guaranteeTypes1: state => guaranteeTypes1,
  projStatus: state => projStatus,
  registerStatus: state => registerStatus,
  listPriceUnits: state => listPriceUnits,
  ownerTypes: state => ownerTypes,
  assignorTypes: state => assignorTypes,
  transferOutAgain: state => transferOutAgain,
  payment: state => payment,
  expiry: state => expiry,
  targeTypes: state => targeTypes,
  assetEquity: state => assetEquity,
  quantityUnit: state => quantityUnit,
  biddingMethod: state => biddingMethod,
  maxQuota: state => maxQuota,
  applyPeopleTypeList: state => applyPeopleTypeList
}
export default getters
