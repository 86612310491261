<template>
	<div>
		<transition name="popup">
			<div v-show="showAuditPopup" class="mask" flex="main:center cross:center">
				<div class="operate-popup" flex="dir:top">
					<p class="tit">{{title}}</p>
					<i class="iconfont icon-close" @click="$emit('change', false)"></i>

					
					<div class="con" flex-box="1">
						<slot name="main"></slot>
					</div>

					<div class="btn" flex="main:right">
						<div @click="$emit('change', false)">返回</div>
						<slot name="btn">
							<div @click="confirm()">提交</div>
						</slot>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: "operatePopup",
	props: {
		showAuditPopup: {},
		title: {}
	},
	model: {
		prop: 'showAuditPopup',
		event: 'change'
	},
	watch: {
	},
	data() {
		return {
		}
	},
	mounted() {
	},
	methods: {
		confirm() {
			this.$emit('confirm', this.auditingVal);
		}
	}
};
</script>

<style scoped lang="scss">
@import '@/styles/input.scss';
@import '@/styles/table.scss';
@import '@/styles/btn.scss';

.operate-popup{
	border-radius: 8px;
	background: #FFFFFF;
	box-shadow: 0px 4px 12px 5px rgba(79, 113, 150, 0.1);
	position: relative;
	max-height: 70%;

	.tit{
		font-family: 思源黑体;
		font-size: 16px;
		font-weight: bold;
		line-height: 25px;
		letter-spacing: 0px;
		color: #4F4F4F;
		padding: 22px 0 22px 42px;
		position: relative;

		&::before{
			width: 4px;
			height: 20px;
			border-radius: 10px;
			opacity: 1;
			background: #0082F5;
			position: absolute;
			left: 28px;
			top: 25px;
			display: block;
			content: '';
		}
	}

	.icon-close{
		position: absolute;
		top: 29px;
		right: 57px;
		font-size: 12px;
		color: #8D8D8D;
		cursor: pointer;
	}

	.con{
		overflow: auto;
	}

	.btn{
		padding-right: 54px;
		padding-bottom: 41px;
		padding-top: 10px;

		::v-deep div{
			width: 100px;
			height: 36px;
			border-radius: 4px;
			opacity: 1;
			background: #fff;
			border: 1px solid #4D99ED;
			text-align: center;
			line-height: 34px;
			color: #4D99ED;
			margin-left: 27px;
			font-family: 思源黑体;
			font-size: 14px;
			font-weight: normal;
			font-feature-settings: "kern" on;
			cursor: pointer;

			&:nth-child(2){
				background: #4D99ED;
				color: #fff;
			}
		}
	}
}
</style>