<template>
	<div>
		<div class="img-box" flex="wrap">
			<div :style="`margin-right: ${imgInterval}px; margin-bottom: ${imgIntervalBottom || imgInterval}px`" v-for="(i, k) in imgList"  :key="k" >
				<el-image :src="i" :preview-src-list="imgList" />
				<el-image class="transparent" :src="i" :preview-src-list="imgList" />
			</div>
		</div>
		<div class="file-box">
			<p :style="`padding-bottom: ${fileInterval}px`" v-for="item,index in fileList" @click="openFile(item)">{{item.split('@')[1] || item.split('@')[0]}}</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: ''
		},
		imgInterval: {
			type: String || Number,
			default: '10'
		},
		imgIntervalBottom: {
			type: String || Number,
			default: '10'
		},
		fileInterval: {
			type: String || Number,
			default: '10'
		}
	},
	data() {
		return {
			imgList: [],
			fileList: []
		};
	},
	watch: {
		value() {
			if(this.value) {
				this.imgList = [];
				this.fileList = [];

				let list = this.value.split(',');

				list.forEach(item => {
					if(item) {
						if(!(item.includes('https://') || item.includes('http://'))) {
							item = this.$store.state.imgUrl + item;
						};

						if(item.includes('.png') || item.includes('.jpg') || item.includes('.gif') || item.includes('.jpeg')) {
							this.imgList.push(item);
						}else {
							this.fileList.push(item);
						}
					}
				})
			}
		}
	},
	mounted() {
		if(this.value) {
			this.imgList = [];
			this.fileList = [];

			let list = this.value.split(',');

			list.forEach(item => {
				if(item) {
					if(!(item.includes('https://') || item.includes('http://'))) {
						item = this.$store.state.imgUrl + item;
					};

					if(item.includes('.png') || item.includes('.jpg') || item.includes('.gif') || item.includes('.jpeg')) {
						this.imgList.push(item);
					}else {
						this.fileList.push(item);
					}
				}
			})
		}
	},
	methods: {
		openFile(file) {
			window.open(file);
		}
	},
};
</script>

<style scoped lang="scss">
.img-box{
	&>div{
		margin: 0 15px 15px 0px;
		height: 117px;
		position: relative;

		&:hover{
			&::before{
				background: rgba(0,0,0,0.3);
				display: block;
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				z-index: 9;
			};

			&::after{
				width: 21px;
				height: 21px;
				background: url('@/assets/imgs/icon/big.png');
				display: block;
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -10px;
				margin-top: -10px;
			}
		}

		.el-image{
			width: 160px;
			height: 117px;

			&.transparent{
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				z-index: 11;
				opacity: 0;
			}

			img{
				display: block;
			}
		}
	}
}

.file-box{
	p{
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		text-decoration: underline;
		color: #4D99ED;
		line-height: 14px;
		padding-bottom: 15px;
		cursor: pointer;
	}
}
</style>

