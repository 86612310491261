<template>
	<transition name="popup">
		<div class="popup-box" v-show="showBox" flex="main:center cross:center">
			<div class="main" flex="dir:top">
				<p class="tit">{{title || '隐私政策'}}</p>
				<div flex-box="1" class="con scroll" v-html="content"></div>
				<div class="btn" @click="count == 0 ? $emit('update:showBox', false) : ''">我知道了{{count ? count + 'S' : ''}}</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "agreementBox",
	props: ['showBox', 'title', 'type'],
	watch: {
		showBox() {
			if(this.showBox) {
				this.count = 3;
				this.getContent();
			}
		}
	},
	data() {
		return {
			content: "",
			count: 3
		}
	},
	created() {
	},
	methods: {
		getContent() {
			this.$ajax({
				url: '/anony/cg/agreement/list',
				method: 'get',
				data: {
					type: this.type || 6,
				},
				success: res => {
					this.content = res.data && res.data.records[0].content;

					let timer = setInterval(() => {
						this.count -= 1;

						if(this.count == 0) {
							clearInterval(timer);
						}
					}, 1000)
				}
			})
		}
	}
};
</script>

<style scoped lang="scss">
@import '@/styles/input.scss';

.popup-box{
	position: fixed;
	background: rgba($color: #000000, $alpha: 0.3);
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1999;

	.main{
		background: #fff;
		width: 800px;
		border-radius: 20px;
		padding: 0 40px;
		height: 45%;

		.tit{
			font-size: 22px;
			padding: 30px 0;
			text-align: center;
		}

		.con{
			background: #f5f5f5;
			padding: 20px;
			font-size: 12px;
			line-height: 18px;
		}

		.btn{
			margin: 30px auto;
			width: 150px;
			height: 50px;
			background: #2E89EE;
			border: 1px solid #FFFFFF;
			border-radius: 25px;
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 50px;
			text-align: center;
			cursor: pointer;
		}
	}
}
</style>