import router from '../router/index.js'

class utils {
	constructor() {}

	// 限制只能输入正整数
	positiveInteger = (data, key) => {
		let newVal = '';
	
		if(data[key] == '0' || data[key] == 0) {
			return 0
		}

		if(data[key].length == 1) {
			newVal = data[key].replace(/[^1-9]/g, '');
		} else {
			newVal = data[key].replace(/\D/g, '');
		}

		data[key] = newVal;
	}

	// 限制只能输入正数，并且最多保留2个小数点
	positiveFloatTwo = (data, key) => {
		let newVal = '';

		if(data[key].split('.')[1] && data[key].split('.')[1].length > 2) {
			newVal = data[key].substring(0, data[key].length - 1);
		}else {
			newVal = data[key].replace(/[^0-9.]/g, '');

			let splitArr = newVal.split('.');
			if(splitArr.length > 2) {
				newVal = '';
				
				for(let i = 0; i < splitArr.length; i ++) {
					if(i == 0) {
						newVal += (splitArr[i] + '.');
					}else {
						newVal += splitArr[i];
					}
				}
			}
		}

		data[key] = newVal;
	}

	// 判断是否是nan
	NaN = val => {
		return isNaN(val) ? 0 : val;
	}

	// 手机校验
	checkPhone = num => {
		return /^[1][3,4,5,6.7,8,9][0-9]{9}$/.test(num)
	}

	// 邮箱校验
	checkEmail = val => {
		return /^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(val)
	}

	// 密码校验
	checkPassword = val => {
		return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(val);
	}

	// 滚动加载更多
	listScroll = (el, page, fn) => {
		el.onscroll = () => {
			if(el.scrollTop + el.offsetHeight >= el.scrollHeight) {
				if(!page.pageTotal) {
					console.log('pageTotal传的值为undefined')
				}

				if(page.pageIndex * page.pageSize >= page.pageTotal) {
					return false;
				}
				page.pageIndex = Number(page.pageIndex) + 1;
				fn();
			}
		}
	}

	// 自动滚动
	autoScrollTimer = [];
	autoScroll = (el, listLength, showLength) => {
		if(!listLength) return;
		if(!showLength) return;
		if(listLength <= showLength) return;
		if(!el) return;
		el.style.transition = 'all 0.3s';
		let max = Math.ceil(listLength / showLength);

		let index = 0;
		let timer = null;
		let height = 0;

		let scrollFn = () => {
			index += 1;
			if(index >= Number(max)) {
				index = 0;
				height = 0;
				el.style.transform = 'translateY(-' + height + 'px)';
				return false;
			}
			let chilrens = el.childNodes;

			let endListLength = 0;
			if(Number(index + 1) * showLength - listLength > 0) {
				endListLength = Number(index + 1) * showLength - listLength;
			}

			for(let i = Number(index - 1) + endListLength; i < Number(index - 1) + showLength; i++) {
				height += chilrens[i] ? chilrens[i].offsetHeight : 0;
				height += parseInt(getComputedStyle(chilrens[i], null)['margin-bottom']);
				height += parseInt(getComputedStyle(chilrens[i], null)['margin-top']);
			}

			el.style.transform = 'translateY(-' + height + 'px)'
		}

		el.onmouseover = function() {
			clearInterval(timer);
		}
		el.onmouseout = function() {
			timer = setInterval(scrollFn, 5000);
		}

		timer = setInterval(scrollFn, 5000);
		this.autoScrollTimer.push(timer);

	}

	// 数字滚动特效
	numberRoll = (data, key, count) => {
		let timer = null;
		let val = data[key];
		
		data[key] = 0;

		let index = 0;
		if(val / count * 30 > 1) {
			timer = setInterval(() => {
				let range = ((val - data[key]) / count * 33).toFixed(2);
				range = Number(range);
				range = range > val * 0.01 ? range : val * 0.01;
				data[key] += range;
				data[key] = Number(data[key].toFixed(2));

				index += 1;

				if(data[key] >= val) {
					clearInterval(timer);
					data[key] = val;
				}
			}, 10);
		}else if(val / count * 30 > 0) {
			timer = setInterval(() => {
				let toFixedVal = ((1 / (val * 30) * 1000).toFixed(0)).length;

				let range = ((val - data[key]) / count * 33).toFixed(toFixedVal);
				range = Number(range);
				range = range > val * 0.01 ? range : val * 0.01;
				console.log(range);
				data[key] += range;
				data[key] = Number(data[key].toFixed(toFixedVal));

				if(data[key] >= val) {
					clearInterval(timer);
					data[key] = val;
				}
			}, 10);
		}else if(val / count * 30 == 0){
			data[key] = 0;
		}
	}

	// 图片加载失败
	imgError = (e) => {
		e.target.src = require('@/assets/imgs/load-error.jpg');
		e.target.style.objectFit = 'cover'
	}

	// 四舍五入
	toFixed(val, length) {
		!length ? length = 2 : '';
		!val ? val = '0' : '';
		val ? val = val.toString() : '';

		if(val.split('.')[1] && val.split('.')[1].length > length) {
			let num1 = Number(val.split('.')[0] || 0);
			let num2 = val.split('.')[1].substr(0, length);
			let num2_1 = num2.toString()[0];
			let num2_2 = num2.toString()[1];
			let toFixedNum = Number(val.split('.')[1][length]);

			if(toFixedNum > 5) {
				num2 = Number(num2) + 1;
				if(num2 >= 100) {
					return (num1 + 1) + '.00';
				}else {
					return num1 + '.' + num2_1 + (Number(num2_2) + 1);
				}
			}else {
				return num1 + '.' + num2_1 + num2_2;
			}
		}else {
			return Number(val * 10).toFixed(0) / 10;
		}
	}
}

export default utils;