export default [
	{
		path: 'circulationProcess', // 流转流程
		name: 'circulationProcess',
		component: function (resolve) {
			require(['../views/my/circulationProcess.vue'], resolve)
		}
	},
	{
		path: 'my', // 个人中心页面
		name: 'my',
		component: function (resolve) {
			require(['../views/my/index.vue'], resolve)
		},
		// redirect: '/my/manaPanel',
		children: [
			{
				path: 'manaPanel', // 管理面板
				name: 'manaPanel',
				component: function (resolve) {
					require(['../views/my/page/manaPanel/index.vue'], resolve)
				}
			},
			{
				path: 'elementMana', // 要素管理
				name: 'elementMana',
				component: function (resolve) {
					require(['../views/my/page/elementMana.vue'], resolve)
				}
			},
			{
				path: 'elementAuditing', // 要素审核
				name: 'elementAuditing',
				component: function (resolve) {
					require(['../views/my/page/elementAuditing.vue'], resolve)
				}
			},
			{
				path: 'digitalAuthentication', // 数字认证
				name: 'digitalAuthentication',
				component: function (resolve) {
					require(['../views/my/page/digitalAuthentication/digitalAuthentication.vue'], resolve)
				}
			},
			{
				path: 'digitalAuthenticationDetail', // 数字认证详情
				name: 'digitalAuthenticationDetail',
				component: function (resolve) {
					require(['../views/my/page/digitalAuthentication/digitalAuthenticationDetail.vue'], resolve)
				}
			},
			{
				path: 'financingCustodyManaMy', // 融托管理 - 我的托管
				name: 'financingCustodyManaMy',
				component: function (resolve) {
					require(['../views/my/page/myTrusteeship/financingCustodyMana.vue'], resolve)
				}
			},
			{
				path: 'financingCustodyMana', // 融托管理 - 托管管理
				name: 'financingCustodyMana',
				component: function (resolve) {
					require(['../views/my/page/myTrusteeship/financingCustodyMana.vue'], resolve)
				}
			},
			{
				path: 'initiateCirculation', // 融托发起流转
				name: 'initiateCirculation',
				component: function (resolve) {
					require(['../views/my/page/myTrusteeship/initiateCirculation.vue'], resolve)
				}
			},
			{
				path: 'financingCustodyDetail', // 融托管理详情
				name: 'financingCustodyDetail',
				component: function (resolve) {
					require(['../views/my/page/myTrusteeship/financingCustodyDetail.vue'], resolve)
				}
			},
			{
				path: 'turnoverRevenue', // 流转营收详情
				name: 'turnoverRevenue',
				component: function (resolve) {
					require(['../views/my/page/myTrusteeship/turnoverRevenue.vue'], resolve)
				}
			},
			{
				path: 'elementCensus', // 要素普查
				name: 'elementCensus',
				component: function (resolve) {
					require(['../views/my/page/elementCensus.vue'], resolve)
				}
			},
			{
				path: 'evaluateMana', // 评估管理
				name: 'evaluateMana',
				component: function (resolve) {
					require(['../views/my/page/evaluateMana.vue'], resolve)
				}
			},
			{
				path: 'enroll', // 我的报名
				name: 'enroll',
				component: function (resolve) {
					require(['../views/my/page/enroll/enroll.vue'], resolve)
				}
			},
			{
				path: 'enrollProjectDetail', // 我的报名-项目报名报名详情
				name: 'enrollProjectDetail',
				component: function (resolve) {
					require(['../views/my/page/enroll/enrollProjectDetail.vue'], resolve)
				}
			},
			{
				path: 'enrollVoucher', // 我的报名-上传凭证
				name: 'enrollVoucher',
				component: function (resolve) {
					require(['../views/my/page/enroll/enrollVoucher.vue'], resolve)
				}
			},
			{
				path: 'supplyEnrollDetail', // 我的报名-供应详情
				name: 'supplyEnrollDetail',
				component: function (resolve) {
					require(['../views/my/page/enroll/supplyEnrollDetail.vue'], resolve)
				}
			},
			{
				path: 'financing', // 我的融资
				name: 'financing',
				component: function (resolve) {
					require(['../views/my/page/financing.vue'], resolve)
				}
			},
			{
				path: 'circulation', // 我的流转
				name: 'circulation',
				component: function (resolve) {
					require(['../views/my/page/myCirculation/circulation.vue'], resolve)
				}
			},
			{
				path: 'noticeAward', // 中标通知书
				name: 'noticeAward',
				component: function (resolve) {
					require(['../views/my/page/myCirculation/noticeAward.vue'], resolve)
				}
			},
			{
				path: 'bidding', // 我的竞价
				name: 'collectiveBidding',
				component: function (resolve) {
					require(['../views/my/page/myCirculation/bidding.vue'], resolve)
				}
			},
			{
				path: 'evaluateDetail', // 评估管理详情
				name: 'evaluateDetail',
				component: function (resolve) {
					require(['../views/my/page/evaluateDetail.vue'], resolve)
				}
			},
			{
				path: 'supplyDemand', // 我的供需
				name: 'supplyDemand',
				component: function (resolve) {
					require(['../views/my/page/supplyDemand.vue'], resolve)
				}
			},
			{
				path: 'riskDisposal', // 风险处置
				name: 'riskDisposal',
				component: function (resolve) {
					require(['../views/my/page/riskDisposal.vue'], resolve)
				}
			},
			{
				path: 'riskDisposalDetail', // 融资风险处理详情
				name: 'riskDisposalDetail',
				component: function (resolve) {
					require(['../views/my/page/riskDisposalDetail.vue'], resolve)
				}
			},
			{
				path: 'riskFund', // 风险资金
				name: 'riskFund',
				component: function (resolve) {
					require(['../views/my/page/riskFund.vue'], resolve)
				}
			},
			{
				path: 'riskFundDetail', // 风险资金详情
				name: 'riskFundDetail',
				component: function (resolve) {
					require(['../views/my/page/riskFundDetail.vue'], resolve)
				}
			},
			{
				path: 'generalSurvey', // 普查数据
				name: 'generalSurvey',
				component: function (resolve) {
					require(['../views/my/page/myCollective/generalSurvey.vue'], resolve)
				}
			},
			{
				path: 'generalSurveyDetail', // 普查数据上传详情
				name: 'generalSurveyDetail',
				component: function (resolve) {
					require(['../views/my/page/myCollective/generalSurveyDetail.vue'], resolve)
				}
			},
			{
				path: 'financialStatistics', // 财村统计
				name: 'financialStatistics',
				component: function (resolve) {
					require(['../views/my/page/myCollective/financialStatistics.vue'], resolve)
				}
			},
			{
				path: 'financialStatisticsDetail', // 财村统计详情
				name: 'financialStatisticsDetail',
				component: function (resolve) {
					require(['../views/my/page/myCollective/financialStatisticsDetail.vue'], resolve)
				}
			},
			{
				path: 'collectiveDisplay', // 集体展示
				name: 'collectiveDisplay',
				component: function (resolve) {
					require(['../views/my/page/myCollective/collectiveDisplay.vue'], resolve)
				}
			},
			{
				path: 'myVillageProduct', // 一村一品
				name: 'myVillageProduct',
				component: function (resolve) {
					require(['../views/my/page/myCollective/myVillageProduct.vue'], resolve)
				}
			},
			{
				path: 'myVillageProductDetail', // 一村一品详情
				name: 'myVillageProductDetail',
				component: function (resolve) {
					require(['../views/my/page/myCollective/myVillageProductDetail.vue'], resolve)
				}
			},
			{
				path: 'myPublicityOfVillageAffairs', // 村务公开
				name: 'myPublicityOfVillageAffairs',
				component: function (resolve) {
					require(['../views/my/page/myCollective/myPublicityOfVillageAffairs.vue'], resolve)
				}
			},
			{
				path: 'myPublicityOfVillageAffairsDetail', // 村务公开详情
				name: 'myPublicityOfVillageAffairsDetail',
				component: function (resolve) {
					require(['../views/my/page/myCollective/myPublicityOfVillageAffairsDetail.vue'], resolve)
				}
			},
			{
				path: 'account', // 账号管理
				name: 'account',
				component: function (resolve) {
					require(['../views/my/page/myCollective/account.vue'], resolve)
				}
			},
			{
				path: 'vote', // 投票
				name: 'vote',
				component: function (resolve) {
					require(['../views/my/page/myCollective/vote.vue'], resolve)
				}
			},
			{
				path: 'voteDetail', // 投票详情
				name: 'voteDetail',
				component: function (resolve) {
					require(['../views/my/page/myCollective/voteDetail.vue'], resolve)
				}
			},
			{
				path: 'attractInvestment', // 招商项目
				name: 'attractInvestment',
				component: function (resolve) {
					require(['../views/my/page/attractInvestment.vue'], resolve)
				}
			},
			{
				path: 'attractInvestmentDetail', // 招商详情
				name: 'attractInvestmentDetail',
				component: function (resolve) {
					require(['../views/my/page/attractInvestmentDetail.vue'], resolve)
				}
			},
			{
				path: 'contract', // 合同
				name: 'contract',
				component: function (resolve) {
					require(['../views/my/page/contract.vue'], resolve)
				}
			},
			{
				path: 'contractDetail', // 合同详情
				name: 'contractDetail',
				component: function (resolve) {
					require(['../views/my/page/contractDetail.vue'], resolve)
				}
			},
			{
				path: 'recruit', // 招聘管理
				name: 'recruit',
				component: function (resolve) {
					require(['../views/my/page/recruit.vue'], resolve)
				}
			},
			{
				path: 'recruitDetail', // 招聘详情
				name: 'recruitDetail',
				component: function (resolve) {
					require(['../views/my/page/recruitDetail.vue'], resolve)
				}
			},
			{
				path: 'recruitLaunch', // 发起招聘
				name: 'recruitLaunch',
				component: function (resolve) {
					require(['../views/my/page/recruitLaunch.vue'], resolve)
				}
			},
			{
				path: 'news', // 新闻
				name: 'news',
				component: function (resolve) {
					require(['../views/my/page/news.vue'], resolve)
				}
			},
			{
				path: 'newsDetail', // 新闻详情
				name: 'newsDetail',
				component: function (resolve) {
					require(['../views/my/page/newsDetail.vue'], resolve)
				}
			},
			{
				path: 'publicGood', // 公益服务
				name: 'publicGood',
				component: function (resolve) {
					require(['../views/my/page/publicGood.vue'], resolve)
				}
			},
			{
				path: 'publicGoodDetail', // 公益服务详情
				name: 'publicGoodDetail',
				component: function (resolve) {
					require(['../views/my/page/publicGoodDetail.vue'], resolve)
				}
			},
			{
				path: 'message', // 我的消息
				name: 'collectiveMessage',
				component: function (resolve) {
					require(['../views/my/page/message.vue'], resolve)
				}
			},
			{
				path: 'industrialEcologicalResources', // 产业生态资源链
				name: 'industrialEcologicalResources',
				component: function (resolve) {
					require(['../views/my/page/bigData/industrialEcologicalResources.vue'], resolve)
				}
			},
			{
				path: 'set', // 设置
				name: 'set',
				component: function (resolve) {
					require(['../views/my/page/set.vue'], resolve)
				}
			}
		]
	}
]