
export default [
	{
		path: 'collectiveEconomyIndex', // 集体经济首页
		name: 'collectiveEconomyIndex',
		component: function (resolve) {
			require(['../views/collectiveEconomy/collectiveEconomyIndex.vue'], resolve)
		}
	},
	{
		path: 'collectiveEconomyDetail', // 集体经济详情页
		name: 'collectiveEconomyDetail',
		component: function (resolve) {
			require(['../views/collectiveEconomy/collectiveEconomyDetail.vue'], resolve)
		}
	},
	{
		path: 'startCollective', // 明星集体页面
		name: 'startCollective',
		component: function (resolve) {
			require(['../views/collectiveEconomy/startCollective.vue'], resolve)
		}
	},
	{
		path: 'startCollectiveDetail', // 明星集体详情
		name: 'startCollectiveDetail',
		component: function (resolve) {
			require(['../views/collectiveEconomy/startCollectiveDetail.vue'], resolve)
		}
	},
	{
		path: 'startCollectiveDetailAlbum', // 明星集体详情相册展示
		name: 'startCollectiveDetailAlbum',
		component: function (resolve) {
			require(['../views/collectiveEconomy/startCollectiveDetailAlbum.vue'], resolve)
		}
	},
	{
		path: 'publicityOfVillageAffairs', // 村务公开
		name: 'publicityOfVillageAffairs',
		component: function (resolve) {
			require(['../views/collectiveEconomy/publicityOfVillageAffairs.vue'], resolve)
		}
	},
	{
		path: 'publicityOfVillageAffairsDetail', // 村务公开详情
		name: 'publicityOfVillageAffairsDetail',
		component: function (resolve) {
			require(['../views/collectiveEconomy/publicityOfVillageAffairsDetail.vue'], resolve)
		}
	},
	// {
	// 	path: 'communityIndex', // 集体经济社区首页列表
	// 	name: 'communityIndex',
	// 	component: function (resolve) {
	// 		require(['../views/collectiveEconomy/communityIndex.vue'], resolve)
	// 	}
	// },
	// {
	// 	path: 'streetDetail', // 集体经济街道详情页
	// 	name: 'streetDetail',
	// 	component: function (resolve) {
	// 		require(['../views/collectiveEconomy/streetDetail.vue'], resolve)
	// 	}
	// },
	// {
	// 	path: 'recruitDetail', // 集体经济招聘详情页
	// 	name: 'recruitDetail',
	// 	component: function (resolve) {
	// 		require(['../views/collectiveEconomy/recruitDetail.vue'], resolve)
	// 	}
	// },
	{
		path: 'voteDetail', // 集体经济投票详情页
		name: 'voteDetail',
		component: function (resolve) {
			require(['../views/collectiveEconomy/voteDetail.vue'], resolve)
		}
	}
]