
export default [
	{
		path: 'financingServer',
		name: 'financingServer',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/financing/financingServer.vue'], resolve)
		},
	},
	{
		path: 'financingDetail',
		name: 'financingDetail',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/financing/financingDetail.vue'], resolve)
		},
	},
	{
		path: 'financingNotice',
		name: 'financingNotice',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/financing/financingNotice.vue'], resolve)
		},
	},
	{
		path: 'financingApply',
		name: 'financingApply',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/financing/financingApply.vue'], resolve)
		},
	},
]