<template>
	<div>
		<div v-show="colorType == 'black'" class="sort" flex="cross:center main:center">
			<img v-show="!up && !down" src="@/assets/imgs/icon/sort-null.png" />
			<img v-show="up" src="@/assets/imgs/icon/sort-up.png" />
			<img v-show="down" src="@/assets/imgs/icon/sort-down.png" />
		</div>
		<div v-show="colorType == 'white'" class="sort" flex="cross:center main:center">
			<img v-show="!up && !down" src="@/assets/imgs/icon/sort-null2.png" />
			<img v-show="up" src="@/assets/imgs/icon/sort-up2.png" />
			<img v-show="down" src="@/assets/imgs/icon/sort-down2.png" />
		</div>
	</div>
</template>

<script>
export default {
	name: "sort",
	props: {
		up: {
			type: Boolean,
			default: false
		},
		down: {
			type: Boolean,
			default: false
		},
		colorType: {
			type: String,
			default: 'black'
		}
	},
	data() {
		return {
			
		}
	},
	methods: {},
	created() { },
};
</script>

<style scoped lang="scss">
img{
	cursor: pointer;
	display: block;
}
</style>