<template>
	<div class="page-banner" flex="main:center cross:top" flex-box="0">
		<div style="margin-right: auto;">
			<p><slot name="t1"></slot></p>
			<span><slot name="t2"></slot></span>
		</div>
		<slot name="img"></slot>
	</div>
</template>

<script>
export default {
	name: 'pageBanner',
	data() {
		return {
		}
	},
	created() {
	},
	methods: {
	}
}
</script>

<style scoped lang="scss">
	.page-banner{
		padding: 0 0 0 362px;
		height: 183px;
		background: url(../assets/imgs/page-banner-bg.png) center center no-repeat;

		div{
			p{
				font-size: 28px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 28px;
				padding: 67px 0 23px 0;
			}

			span{
				font-size: 28px;
    		font-family: Microsoft YaHei;
				font-weight: 300;
				color: #FFFFFF;
				line-height: 26px;
				text-transform: uppercase;
			}
		}

		img{
			margin-left: auto !important;
		}
	}
</style>
