<template>
	<div>
		<transition name="popup">
			<div v-show="showAuditPopup" class="mask" flex="main:center cross:center">
				<div class="auditing">
					<p class="tit">{{title || '驳回原因'}}</p>
					<i class="iconfont icon-close" @click="$emit('change', false)"></i>

					<div class="con">
						<div class="text" flex>
							<p class="l-t">{{title || '驳回原因'}}：</p>
							<el-input v-model="auditingVal" type="textarea" flex-box="1" placeholder="请输入"></el-input>
						</div>
					</div>
					
					<div class="btn" flex="main:right">
						<div @click="$emit('change', false)">返回</div>
						<div @click="confirm()">提交</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: "auditPopup",
	props: {
		showAuditPopup: {},
		title: {}
	},
	model: {
		prop: 'showAuditPopup',
		event: 'change'
	},
	watch: {
		showAuditPopup() {
			if(this.showAuditPopup) {
				this.auditingVal = '';
			}
		}
	},
	data() {
		return {
			auditingVal: ''
		}
	},
	mounted() {
	},
	methods: {
		confirm() {
			this.$emit('confirm', this.auditingVal);
		}
	}
};
</script>

<style scoped lang="scss">
@import '@/styles/input.scss';
@import '@/styles/table.scss';
@import '@/styles/btn.scss';


.auditing{
	width: 1024px;
	border-radius: 8px;
	background: #FFFFFF;
	box-shadow: 0px 4px 12px 5px rgba(79, 113, 150, 0.1);
	position: relative;

	.tit{
		font-family: 思源黑体;
		font-size: 16px;
		font-weight: bold;
		line-height: 25px;
		letter-spacing: 0px;
		color: #4F4F4F;
		padding: 22px 0 63px 42px;
		position: relative;

		&::before{
			width: 4px;
			height: 20px;
			border-radius: 10px;
			opacity: 1;
			background: #0082F5;
			position: absolute;
			left: 28px;
			top: 25px;
			display: block;
			content: '';
		}
	}

	.icon-close{
		position: absolute;
		top: 29px;
		right: 57px;
		font-size: 12px;
		color: #8D8D8D;
		cursor: pointer;
	}

	.con{
		max-height: 600px;
		overflow: auto;
	}

	.text{
		padding: 0 54px 34px 42px;

		.l-t{
			font-family: 思源黑体;
			font-size: 14px;
			font-weight: normal;
			line-height: 25px;
			letter-spacing: 0px;
			color: #4F4F4F;
			flex-shrink: 0;
			width: 100px;

			span{
				color: #D44747;
			}
		}

		.el-textarea{
			height: 200px;
			border-radius: 2px;
			opacity: 1;
			background: #FFFFFF;
			box-sizing: border-box;

			/deep/ textarea{
				border: 1px solid #D1D1D1;
				font-size: 14px;
				color: #4F4F4F;
				padding: 10px;
				line-height: 18px;
			}
		}
	}

	.btn{
		padding-right: 54px;
		padding-bottom: 41px;
		padding-top: 33px;

		div{
			width: 100px;
			height: 36px;
			border-radius: 4px;
			opacity: 1;
			background: #fff;
			border: 1px solid #4D99ED;
			text-align: center;
			line-height: 34px;
			color: #4D99ED;
			margin-left: 27px;
			font-family: 思源黑体;
			font-size: 14px;
			font-weight: normal;
			font-feature-settings: "kern" on;
			cursor: pointer;

			&:nth-child(2){
				background: #4D99ED;
				color: #fff;
			}
		}
	}
}
</style>