<template>
	<div class="main">
		<!-- 按钮 -->
		<div v-show="type == 'check'">
			<ul class="check" flex>
				<li v-for="item,index in (fileList || [])" :key="index">
					<el-image style="width: 159px; height: 94px;" class="img" :src="item.url" fit="cover"/>
					<div class="operate" flex="main:center cross:center">
						<i @click="imgViewerIndex = index; imgViewerVisible = true; srcList = fileList.map(item => item.url);" class="el-icon-zoom-in"></i>
						<i @click="fileList.splice(index, 1); changeFileList(fileList)" class="el-icon-delete"></i>
						<div @click="$emit('update:checkVal', item.uploadUrl);" class="check-icon" :class="{'check': checkVal == item.url}" flex="cross:center main:center">
							<i class="el-icon-check"></i>
						</div>
					</div>
				</li>
			</ul>
			<el-upload :multiple="multiple" class="btn-box" flex="wrap" :show-file-list="false" action="#" list-type="picture-card" :file-list="fileList" :http-request="httpUpload"  :on-change="handleChange" :on-remove="handleRemove" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :before-upload="beforeUpload" :before-remove="beforeRemove" :limit="maxlimit" :disabled="disabled">
				<div flex="cross:center">
					<div v-show="((maxlimit && maxlimit > fileList.length) || !maxlimit) && !disabled" class="upload-btn">{{btnVal || '+点击添加照片'}}</div>
					<span style="font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #ABAEBA; padding-left: 11px;">{{ tips || '' }}</span>
				</div>
			</el-upload>
		</div>
		<div v-show="type == 'btn'">
			<el-upload :multiple="multiple" class="btn-box" flex="wrap" :show-file-list="showFileList && !showCheck" action="#" list-type="picture-card" :file-list="fileList" :http-request="httpUpload"  :on-change="handleChange" :on-remove="handleRemove" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :before-upload="beforeUpload" :before-remove="beforeRemove" :limit="maxlimit" :disabled="disabled">
				<div flex="cross:center">
					<div v-show="((maxlimit && maxlimit > fileList.length) || !maxlimit) && !disabled" class="upload-btn">{{btnVal || '+点击添加照片'}}</div>
					<span style="font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #ABAEBA; padding-left: 11px;">{{ tips || '' }}</span>
				</div>
			</el-upload>
		</div>
		<!-- 身份证正面 -->
		<div v-show="type == 'idPositive'">
			<el-upload :multiple="multiple" class="id-img" action="#" :show-file-list="false && showFileList" list-type="picture-card" :file-list="fileList" :http-request="httpUpload"
				 :on-change="handleChange" :on-remove="handleRemove"
				:on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :before-upload="beforeUpload"
				:before-remove="beforeRemove" :limit="maxlimit" :disabled="disabled">
				<div class="img-w">
					<i v-if="fileList[0] && !disabled" class="el-icon-close close-btn" @click.stop="fileList = []; $emit('input', '')"></i>
					<img v-if="fileList[0]" :src="fileList[0].url" class="avatar">
					<img v-else src="../assets/imgs/icon/upload-id-posi.png" />
				</div>
			</el-upload>
		</div>
		<!-- 身份证反面 -->
		<div v-show="type == 'idBack'">
			<el-upload :multiple="multiple" class="id-img" action="#" :show-file-list="false && showFileList" list-type="picture-card" :file-list="fileList" :http-request="httpUpload"
				 :on-change="handleChange" :on-remove="handleRemove"
				:on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :before-upload="beforeUpload"
				:before-remove="beforeRemove" :limit="maxlimit" :disabled="disabled">
				<div class="img-w">
					<i v-if="fileList[0] && !disabled" class="el-icon-close close-btn" @click.stop="fileList = []; $emit('input', '')"></i>
					<img v-if="fileList[0]" :src="fileList[0].url" class="avatar">
					<img v-else src="../assets/imgs/icon/upload-id-back.png" />
				</div>
			</el-upload>
		</div>
		<!-- 卡片 -->
		<div v-show="type == 'card'">
			<el-upload :multiple="multiple" class="btn-box" action="#" :show-file-list="showFileList" list-type="picture-card" :file-list="fileList" :http-request="httpUpload"
				 :on-change="handleChange" :on-remove="handleRemove"
				:on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :before-upload="beforeUpload"
				:before-remove="beforeRemove" :limit="maxlimit" :disabled="disabled">
				<div v-show="((maxlimit && maxlimit > fileList.length) || !maxlimit) && !disabled" class="upload-btn2" flex="cross:center main:center">
					<img src="../assets/imgs/icon/add-img-icon.png" />
				</div>
			</el-upload>
		</div>
		<!-- 透明 -->
		<div v-show="type == 'transparent'">
			<el-upload :multiple="multiple" class="transparent" action="#" :show-file-list="false" list-type="picture-card" :file-list="fileList" :http-request="httpUpload"
				 :on-change="handleChange" :on-remove="handleRemove"
				:on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :before-upload="beforeUpload"
				:before-remove="beforeRemove" :limit="maxlimit" :disabled="disabled">
				<div v-show="((maxlimit && maxlimit > fileList.length) || !maxlimit) && !disabled" class="upload-btn2" flex="cross:center main:center">
					<img src="../assets/imgs/icon/add-img-icon.png" />
				</div>
			</el-upload>
		</div>
		<!-- 其他 -->
		<div v-show="type == 'other'">
			<el-upload :multiple="multiple" class="other-box" flex="wrap" :show-file-list="false" action="#" list-type="picture-card" :file-list="fileList" :http-request="httpUpload"  :on-change="handleChange" :on-remove="handleRemove" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :before-upload="beforeUpload" :before-remove="beforeRemove" :limit="maxlimit" :disabled="disabled">
				<ul>
					<li flex="cross:center" @click.stop="openFile(item)" v-for="item,index in fileList">{{item.name}} <i v-show="!disabled" @click.stop="fileList.splice(index, 1), changeFileList(fileList)" class="el-icon-close"></i></li>
				</ul>
				<div v-show="((maxlimit && maxlimit > fileList.length) || !maxlimit) && !disabled" class="upload-btn">{{btnVal || '上传文件'}}</div>
			</el-upload>
		</div>
		<!-- 其他-----文字 -->
		<div v-show="type == 'text'">
			<el-upload :multiple="multiple" class="other-box" flex="wrap" :show-file-list="false" action="#" list-type="picture-card" :file-list="fileList" :http-request="httpUpload"  :on-change="handleChange" :on-remove="handleRemove" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :before-upload="beforeUpload" :before-remove="beforeRemove" :limit="maxlimit" :disabled="disabled">
				<ul>
					<li class="ellipsis1" flex="cross:center" @click.stop="openFile(item)" v-for="item,index in fileList">{{item.name}} <i v-show="!disabled" @click.stop="fileList.splice(index, 1), changeFileList(fileList)" class="el-icon-close"></i></li>
				</ul>
				<div v-show="((maxlimit && maxlimit > fileList.length) || !maxlimit) && !disabled" class="upload-text">{{textVal || '+点击上传'}}</div>
			</el-upload>
		</div>
		<!-- 视频 -->
		<div v-show="type == 'video'">
			<el-upload :multiple="multiple" class="btn-box video-box" action="#" :show-file-list="false" list-type="picture-card" :file-list="fileList" :http-request="httpUpload"
				 :on-change="handleChange" :on-remove="handleRemove"
				:on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :before-upload="beforeUpload"
				:before-remove="beforeRemove" :limit="maxlimit" :disabled="disabled">
				<div class="video-group" flex="wrap">
					<!-- {{fileList}} -->
					<div v-for="item,index in fileList" class="posi-r" @click.stop="null">
						<div class="close-btn box-shadow" flex="main:center cross:center" @click.stop="fileList.splice(index, 1); $emit('input', '')">
							<img src="@/assets/imgs/icon/close-icon-black.png" />
						</div>
						<video @loadstart="getVideoInfo" controls="controls">
							<source :src="item.url" type="video/mp4" />
						</video>
					</div>
					<div v-show="((maxlimit && maxlimit > fileList.length) || !maxlimit) && !disabled" class="upload-btn2" flex="cross:center main:center">
						<img src="../assets/imgs/icon/add-img-icon.png" />
					</div>
				</div>
			</el-upload>
		</div>

		<!-- 大图弹窗 -->
		<!-- <el-dialog :modal="false" :visible.sync="imgViewerVisible">
			<img width="100%" :src="srcList" alt />
		</el-dialog> -->

		<el-image-viewer
			v-if="imgViewerVisible"
			:initialIndex="imgViewerIndex"
			:onClose="closeImgViewer"
			:url-list="srcList">
		</el-image-viewer>
	</div>
</template>

<script>
import ImageViewer from 'element-ui/packages/image/src/image-viewer';

export default {
	props: {
		type: {
			type: [Number, String],
			default() {
				return 0;
			},
		},
		value: {
			type: [String, Object, Array],
			default: "",
		},
		checkVal: {
			type: [String, Object, Array],
			default: "",
		},
		// 文件大小 默认 20mb
		size: {
			type: Number,
			default: 20,
		},
		// 最大上传数
		maxlimit: {
			type: Number,
			default: 10,
		},
		fileType: {
			type: Array,
			default: () => ["jpg", "png", "jpeg", "docx", "xlsx", "mp4", "txt", "pdf", "word"],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		//操作类型
		action: {
			type: Number,
			default: 1,
		},
		isSee: {
			type: Boolean,
			default: false
		},
		btnVal: {
			type: String,
			default: ''
		},
		tips: {
			type: String,
			default: ''
		},
		textVal: {
			type: String,
			default: ''
		},
		showFileList: {
			type: Boolean,
			default: true
		},
		showCheck: {
			type: Boolean,
			default: false
		},
		url: {
			type: String,
			default: ''
		},
		multiple: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hideUploadEdit: false, // 是否隐藏上传按钮
			/* VUE_APP_OSS: process.env.VUE_APP_OSS, */
			VUE_APP_OSS: this.$store.state.imgUrl,
			fileList: [],
			tempFileList: [],
			loading: false,
			imgViewerIndex: 0,
			imgViewerVisible: false,
			srcList: [],
		};
	},
	components: {
		'el-image-viewer': ImageViewer
	},
	mounted() { },
	watch: {
		isSee() {
			this.disabled = this.isSee;
		},
		value: {
			immediate: true,
			handler: function (fileListS) {
				if (!fileListS) {this.fileList = []; return};

				let fileListStr = fileListS || "";
				const fileArr = fileListStr.split(","); // 文件地址
				var fileObj = [];



				fileArr.forEach((item) => {

					if (/^http.*/gi.test(item) || /^https.*/gi.test(item)) {
						const obj = {
							response: {}
						};
						obj.name = item.split('@')[1] || item.split('@')[0];
						obj.url = item;
						obj.uploadUrl = item;
						fileObj.push(obj);
					} else {
						const obj = {
							response: {}
						};
						obj.name = item.split('@')[1] || item.split('@')[0];
						obj.url = this.VUE_APP_OSS + item;
						obj.uploadUrl = item;
						fileObj.push(obj);
					}
				});

				this.fileList = fileObj;
			},
		},
		fileList: {
			deep: true,
			handler: function (n, o) {
				if (n.length < this.maxlimit) {
					this.hideUploadEdit = false;
				} else {
					this.hideUploadEdit = true;
				}
			},
		},
	},
	mounted() { },
	methods: {
		closeImgViewer() {
			this.imgViewerVisible = false;
		},
		getVideoInfo(e) {
		},
		openFile(item) {
			window.open(item.url);
		},
		changeFileList(fileList) {
			let tempUrl = "";
			const tempFileList = fileList
				.map((item, index) => {
					if (item.response && item.response.data) {
						tempUrl = item.response ? item.response.data : item.url;
						if (/^http.*/gi.test(tempUrl) || /^https.*/gi.test(tempUrl)) {
							tempUrl = tempUrl.replace(`/^${this.VUE_APP_OSS}?:\/\//`, "");
						}
					} else {
						tempUrl = item.uploadUrl || "cs/ss/a.png";
					}

					return tempUrl;
				})
				.join(",");

			this.$emit("input", tempFileList);
		},
		// 点击已上传文件时
		handlePictureCardPreview(file) {
			this.imgViewerIndex = this.fileList.findIndex(item => item.url == file.url);
			this.srcList = this.fileList.map(item => item.url);
			this.imgViewerVisible = true;
		},
		httpUpload(params) {
			const formData = new FormData();
			formData.append("file", params.file);

			this.loading = true;

			this.$ajax({
				url: this.url || "/anony/save",
				method: "post",
				data: formData,
				success: (res) => {
					let index = 0;
					this.fileList.forEach(item => {
						if(item.name == params.file.name) {
							item.response = res;
						}

						if(item.response) {
							index ++;
						}


						if(index == this.fileList.length) {
							this.loading = false;
							this.$message.success("上传成功");
							this.changeFileList(this.fileList);
						}
					})
				},
			});
		},
		handleChange(file, fileList) {
			if(this.type == 'idPositive' || this.type == 'idBack' || Number(this.maxlimit) == 1) {
				fileList = [fileList[fileList.length - 1]];
			}
			
			this.fileList = fileList.concat();
		},
		handleExceed(file, fileList) {
			this.$message.warning(`最多上传${this.maxlimit}个文件`);
		},
		handleRemove(file, fileList) {
			this.fileList = fileList.concat();

			// 判断图片长度
			/*  if (this.fileList.length < this.maxlimit) {
			  this.hideUploadEdit = false;
			} */
			this.changeFileList(fileList);
		},
		beforeUpload(file) {
			if (this.fileType && file) {
				var FileExt = file.name.replace(/.+\./, "");
				const isFormat = this.fileType.indexOf(FileExt.toLowerCase()) === -1;

				if (isFormat) {
					this.$message.error(`上传文件只能是 ${this.fileType} 格式！`);
					return false;
				}
			}

			if (this.size && file) {
				const isLtM = file.size / 1024 / 1024 < this.size;
				if (!isLtM) {
					this.$message.error(`上传文件大小不能超过 ${this.size} MB!`);
					return false;
				}
			}

			return true;
		},
		beforeRemove(file, fileList) {
			// return this.$confirm(`确定移除 ${file.name}？`);
		},
	},
};
</script>

<style scoped lang="scss">
.main{
	.btn-box ::v-deep{
		&>ul{
			width: 100%;

			li{
				border: none;
				margin-right: 10px;
				margin-bottom: 10px;
				width: 159px;
				height: 94px;

				*{
					border: none;
				}

				&.el-upload-list__item{
					transition: none !important;
				}
			}
		}

		.el-upload-list__item-status-label{
			display: none !important;
		}

		.el-upload--picture-card{
			width: auto;
			border: none;
			height: auto;
			line-height: normal;

			.upload-btn{
				width: 128px;
				height: 30px;
				background: #4D99ED;
				border-radius: 2px;
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 30px;
				text-align: center;
			}
		}
	}


	.id-img ::v-deep{
		.el-upload--picture-card{
			width: auto;
			border: none;
			height: auto;
			line-height: normal;
		}

		.img-w{
			width: 159px;
			height: 94px;
			margin-bottom: 10px;
			position: relative;
			
			img{
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 3px;
			}
		}
	}

	.upload-btn2{
		background: #FFFFFF;
		border: 1px solid #D1D1D1;
		border-radius: 2px;
		width: 159px;
		height: 94px;
		margin-bottom: 10px;
	}

	.transparent{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		opacity: 0;
	}

	.other-box ::v-deep{
		background: #fff;

		ul{
			width: 100%;

			li{
				height: auto;
				padding: 8px 0;
				font-size: 14px;
				line-height: 18px;
				color: #4D99ED;

				i{
					font-size: 18px;
					padding-left: 10px;
					cursor: pointer;
				}
			}
		}

		.el-upload-list__item-status-label{
			display: none !important;
		}

		.el-upload--picture-card{
			width: auto;
			border: none;
			height: auto;
			line-height: normal;

			.upload-btn{
				width: 128px;
				height: 30px;
				background: #4D99ED;
				border-radius: 2px;
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 30px;
				text-align: center;
			}

			.upload-text{
				font-size: 14px;
				font-weight: 400;
				text-decoration: underline;
				color: #4D99ED;
				line-height: 14px;
				padding: 8px 0 0px;
				text-align: left;
			}
		}
	}

	.video-box{
		.video-group{
			// .video-btn{
			// 	width: 20px;
			// 	height: 20px;
			// 	background: red;
			// 	position: absolute;
			// 	left: 50px;
			// 	top: 50px;
			// 	z-index: 11;
			// }

			video{
				width: 159px;
				height: 94px;
				border: 1px solid #D1D1D1;
				margin-right: 10px;
				margin-bottom: 10px;
			}

			.close-btn{
				position: absolute;
				right: 0px;
				top: -10px;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				border: 1px solid #ccc;
				background: #fff;
			}
		}
	}
}


.close-btn{
	background: #4d99ed;
	font-size: 14px;
	position: absolute;
	color: #fff;
	top: -8px;
	right: -8px;
	border-radius: 50%;
	width: 18px;
	height: 18px;
	text-align: center;
	line-height: 18px;
}


.check{
	li{
		position: relative;
		width: 159px;
		height: 94px;
		margin-bottom: 10px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;

		&:hover{
			.operate{
				z-index: 11;
				opacity: 1;
			}
		}

		.operate{
			position: absolute;
			left: 0;
			top: 0;
			z-index: 999;
			background: rgba(0,0,0,.5);
			width: 100%;
			height: 100%;
			z-index: -9;
			transition: opacity .3s;
			opacity: 0;

			> i{
				color: #fff;
				speak: none;
				font-style: normal;
				font-weight: 400;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				vertical-align: baseline;
				display: inline-block;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				font-size: 20px;
				cursor: pointer;
				
				&:first-child{
					margin-right: 15px;
				}
			}

			.check-icon{
				width: 18px;
				height: 18px;
				border-radius: 2px;
				background: #FFFFFF;
				box-sizing: border-box;
				border: 1px solid #D1D1D1;
				position: absolute;
				right: 5px;
				top: 4px;
				cursor: pointer;
				&.on{
					background: #4D99ED;
				}

				i{
					color: #fff;
					font-size: 16px;
					font-weight: 900;
				}
			}
		}
	}
}
</style>

