import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
// 通用方法
import ajax from './utils/ajax'
import utils from './utils/utils'
Vue.prototype.$ajax = ajax
Vue.prototype.$utils = new utils();
Vue.prototype.$bus = new Vue();
import './utils/prototype'
// md5
import md5 from 'md5';
Vue.prototype.$md5 = md5
// socket
// import socket from './utils/socket';
// Vue.prototype.$socket = new socket();
// moment
import moment from 'moment';
Vue.prototype.$moment = moment;
// elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// 全局组件
import keepOnRecord from './components/keepOnRecord.vue'; // 备案
import pageBanner from './components/pageBanner.vue'; // 页面顶部横幅
import emptyTableList from './components/emptyTableList.vue'; // 表格空数据
import uploadFile from './components/uploadFile.vue'; // 上传
import pagination from './components/pagination.vue' // 分页
import sort from './components/sort.vue' // 排序
import signBox from './components/signBox.vue' // 签名
import loginBox from './components/loginBox.vue' // 登录框
import fileListShow from './components/fileListShow.vue'; // 文件展示
import crumbs from './components/crumbs.vue'; // 面包屑导航
import editor from './components/editor.vue'; // 富文本
import auditPopup from './components/auditPopup.vue'; // 审核弹窗
import operatePopup from './components/operatePopup.vue'; // 操作弹窗
import agreementBox from './components/agreementBox.vue'; // 协议弹窗
Vue.component("keepOnRecord", keepOnRecord);
Vue.component("pageBanner", pageBanner);
Vue.component("uploadFile", uploadFile);
Vue.component("emptyTableList", emptyTableList);
Vue.component("pagination", pagination);
Vue.component("sort", sort);
Vue.component("signBox", signBox);
Vue.component("loginBox", loginBox);
Vue.component("fileListShow", fileListShow);
Vue.component("crumbs", crumbs);
Vue.component("editor", editor);
Vue.component("auditPopup", auditPopup);
Vue.component("operatePopup", operatePopup);
Vue.component("agreementBox", agreementBox);
// 图片放大
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview, {bgOpacity: 0.5, hideAnimationDuration:0, showAnimationDuration:0});
// 文件下载
import downLoadUrl from './directive/downLoadUrl/index'
Vue.use(downLoadUrl)

// 样式
import '../src/styles/table.scss';


// 路由拦截
router.beforeEach((to, from, next) => {
	// if(localStorage.getItem('userToken') && to.name == 'login') {
	// 	next({name: 'home'})
	// 	return false;
	// }

	// if(!localStorage.getItem('userToken') && to.name != 'login') {
	// 	sessionStorage.removeItem("keepAlive");
	// 	sessionStorage.removeItem("menuActiveUrl");
	// 	sessionStorage.removeItem("labelList");
	// 	sessionStorage.removeItem("checkLabelName");
		
	// 	next({name: 'login'})
	// 	return false;
	// }

	// 清除自动滚动的定时器
	Vue.prototype.$utils.autoScrollTimer.forEach(item => {
		clearInterval(item);
	})

	next();
})

// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap, {
// 	ak: 'PGPty1V0NKdeKmPSEkdRyIcjtrxT7gUq',
// 	// ak: "rQxHPQX1ua21rWnXzTbUh5KSSUbnefxR"
// })

new Vue({
	router,
	store,
	created() {
		// ElementUI.Message({
		// 	showClose: true,
		// 	message: 'res.data.message',
		// 	type: 'error'
		// });
	},
	render: h => h(App)
}).$mount('#main-app');

// 微应用
// import { registerMicroApps, start } from 'qiankun';
// const getActiveRule = (hash) => (location) => location.hash.startsWith(hash);
// // 注册各个微应用
// registerMicroApps([
// 	// 集体经济
// 	{
// 		name: 'my',
// 		entry: 'http://localhost:10001',
// 		container: '#collective-economy',
// 		activeRule: getActiveRule('#/my'),
// 	}
// ]);

// start({
// 	prefetch: false
// }); // 启动qiankun
