
export default [
	{
		path: 'trustCenter',
		name: 'trustCenter',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/trustCenter/index.vue'], resolve)
		},
	},
	{
		path: 'startTrust',
		name: 'startTrust',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/trustCenter/start.vue'], resolve)
		},
	},
]